import { radiansToDegrees } from './utils';
const Cesium = window.Cesium;
const EGM96_mu = 3.986004415e14; // m^3/s^2
const TwoPi = 2 * Math.PI;

const makeInt = (elems) => {
  elems.aphelion_distance = parseFloat(elems.aphelion_distance);
  elems.ascending_node_longitude = parseFloat(elems.ascending_node_longitude);
  elems.eccentricity = parseFloat(elems.eccentricity);
  elems.inclination = parseFloat(elems.inclination);
  elems.mean_anomaly = parseFloat(elems.mean_anomaly);
  elems.mean_motion = parseFloat(elems.mean_motion);
  elems.perihelion_argument = parseFloat(elems.perihelion_argument);
  elems.perihelion_distance = parseFloat(elems.perihelion_distance);
  elems.semi_major_axis = parseFloat(elems.semi_major_axis);
}

const eltocart = (Cesium, ele, posonly = false, tol = 1e-6, MAXITER = 20) => {
  var ecan = ecceanom(ele.MeanAnom, ele.eccentricity, tol, MAXITER);
  var tran =
    2 *
    Math.atan2(
      Math.sqrt((1 + ele.eccentricity) / (1 - ele.eccentricity)) * Math.sin(ecan / 2),
      Math.cos(ecan / 2)
    );

  var p = ele.semi_major_axis * (1 - ele.eccentricity * ele.eccentricity);
  var r = p / (1 + ele.eccentricity * Math.cos(tran));
  var h = Math.sqrt(EGM96_mu * p);

  var ci = Math.cos(ele.inclination);
  var si = Math.sin(ele.inclination);
  var cr = Math.cos(ele.ascending_node_longitude);
  var sr = Math.sin(ele.ascending_node_longitude);
  var cw = Math.cos(ele.perihelion_argument + tran);
  var sw = Math.sin(ele.perihelion_argument + tran);

  var pos = new Cesium.Cartesian3(
    cr * cw - sr * sw * ci,
    sr * cw + cr * sw * ci,
    si * sw
  );
  var pos2 = new Cesium.Cartesian3();
  Cesium.Cartesian3.multiplyByScalar(pos, r, pos2);
  if (posonly) return pos2;

  var vel = new Cesium.Cartesian3();
  var vel1 = new Cesium.Cartesian3();
  var vel2 = new Cesium.Cartesian3();
  Cesium.Cartesian3.subtract(
    Cesium.Cartesian3.multiplyByScalar(
      pos2,
      (h * ele.eccentricity * Math.sin(tran)) / (r * p),
      vel1
    ),
    Cesium.Cartesian3.multiplyByScalar(
      new Cesium.Cartesian3(
        cr * sw + sr * cw * ci,
        sr * sw - cr * cw * ci,
        -si * cw
      ),
      h / r,
      vel2
    ),
    vel
  );

  return { pos: pos2, vel: vel };
}

const ecceanom  = (mean, ecc, tol, MAXITER) => {
  var prev = mean;
  for (let i = 1; i <= MAXITER; i++) {
    var curr =
      prev -
      (prev - ecc * Math.sin(prev) - mean) / (1 - ecc * Math.cos(prev))
    if (Math.abs(curr - prev) <= tol) return curr % TwoPi;
    prev = curr;
  }

  return NaN;
};

const calculateOrbit = (elems, startTime, endTime, step) => {
  const positionSamples = [];
  let car = new Cesium.Cartographic();
  let Y = new Cesium.Cartesian3();
  let sta;

  const fromTime = Cesium.JulianDate.toDate(startTime).valueOf();
  const toTime = Cesium.JulianDate.toDate(endTime).valueOf();
  for (let t = fromTime; t <= toTime; t += step) {
    const _elems = Object.assign({}, elems);
    const timePoint = new Cesium.JulianDate.fromDate(new Date(t));
    const secondsAdvanced = Cesium.JulianDate.secondsDifference(
      timePoint,
      startTime
    );
    _elems.MeanAnom = (_elems.MeanAnom + _elems.mmo * secondsAdvanced) % TwoPi;
    if (_elems.MeanAnom === 0) {
      sta = eltocart(Cesium, _elems, false, 1e-6, 100);
      Y = sta.pos;
    } else {
      // do other stuff
      sta = eltocart(Cesium, _elems, true, 1e-6, 100);
      Y = sta;
    }
    Cesium.Ellipsoid.WGS84.cartesianToCartographic(Y, car);
    if (
      Number.isNaN(car.longitude) ||
      Number.isNaN(car.latitude) ||
      Number.isNaN(car.height)
    ) {
      continue;
    }
    positionSamples.push({time: Cesium.JulianDate.toDate(timePoint), radial: car.height, point: [radiansToDegrees(car.longitude), radiansToDegrees(car.latitude)]});
  }
  return positionSamples;
};

const compileElements = (asteroid) => {
  const elems = asteroid.orbitalElements;
  makeInt(elems);
  const endTime = asteroid.endTime;
  const epjd = Cesium.JulianDate.fromDate(new Date(new Date().valueOf() - (10 * 60 * 1000)));
  const orbitEnd = Cesium.JulianDate.fromDate(new Date(endTime));
  let t = Cesium.JulianDate.daysDifference(orbitEnd, epjd);
  elems.semi_major_axis = parseFloat(elems.semi_major_axis);
  elems.mmo = Math.sqrt(EGM96_mu / (elems.semi_major_axis * elems.semi_major_axis * elems.semi_major_axis));
  elems.MeanAnom = (parseFloat(elems.mean_anomaly) + elems.mmo * t * 86400) % TwoPi;
  // Calculate Orbit
  asteroid.path = calculateOrbit(elems, epjd, orbitEnd, 10 * 60 * 1000);
}

export default compileElements;
