import {metresToKm, AUinMetres} from '../services/utils';

class Planet {
  constructor(name) {
    this.name = name;
    this.id = `planet-${name}`;
    this.path = [];
    this.status = {on: true, visible: true, nextVisible: null};
  }
  addToPath(coords, altitude, time) {
    /*coords.x = coords.x * AUinMetres;
    coords.y = coords.y * AUinMetres;
    coords.z = coords.z * AUinMetres;*/
    const {x, y, z} = coords;
    altitude = altitude * AUinMetres;
    const altText = metresToKm(altitude);
    time = new Date(time);
    this.path.push({
      time,
      altitude,
      altText,
      point: [x * AUinMetres, y * AUinMetres, z * AUinMetres]
    });
  }
}

export default Planet;