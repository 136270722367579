const uiService = () => {
  const funcs = {};
  return {
    register: (func, key) => {
      funcs[key] = func;
    },
    use: (key, args) => funcs[key] ? funcs[key](...args) : () => {}
  }
}

export default uiService();