import React, { Component } from 'react';
import {metresToKm, formatNumber} from '../services/utils';

const coordFormatter = formatNumber(3);

class PositionShower extends Component {

    state = {
        lat: this.props.satellite.position[1],
        long: this.props.satellite.position[0],
        altitude: this.props.satellite.altitude,
    }

    componentDidMount() {
        this._intervalRecord = setInterval(() => {
            if (this.props.satellite) {
                this.setState({altitude: this.props.satellite.altitude, lat: this.props.satellite.position[1], long: this.props.satellite.position[0] });
            }
        }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this._intervalRecord);
    }

    render() {
        const {lat, long, altitude} = this.state; 
        return (
            <span>
                <p><strong>Height: </strong> {metresToKm(altitude)}</p>
                <p><strong>Lat: </strong>{coordFormatter(lat)} <strong>Long: </strong>{coordFormatter(long)}</p>
            </span>
        );
    }
}

export default PositionShower;