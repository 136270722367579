import React, { Component } from 'react';
import './SatelliteList.css';
import SatelliteService from '../services/satellite-service';
import Settings from '../services/settings-service';
import {searchFilter} from '../services/utils';
import Spinner from './Spinner';
import Radio from './ui/Radio'
import Banner from './ads/Banner';
import UiService from '../services/ui-link-service';
import { Plugins } from '@capacitor/core';
const { App } = Plugins;

class SatelliteList extends Component {
    state = {
        satellites: [],
        filter: SatelliteService.filters[0],
        search: '',
        isLoading: true,
        showDropdown: false,
        selectedRepo: SatelliteService.repos.find(r => r.status()),
    }

    componentDidMount() {
        Settings.getSetting('filter')
            .then(f => {
                if (f) {
                    const filter = SatelliteService.filters.find(filter => filter.label === f);
                    if (filter) {
                        this.setState({filter});
                    }
                }
            });
        SatelliteService.getSubscribe(satellites => {
            const filter = this.state.filter;
            this.applyFilter(filter, satellites);
            this.setState({satellites, isLoading: false, selectedRepo: SatelliteService.repos.find(r => r.status())});
        }, () => this.setState({isLoading: false}));
        SatelliteService.subscribe(this.forceUpdate);
        SatelliteService.onSelect(() => this.forceUpdate(this.state.satellites));
        UiService.register(() => this.showDropdown(), 'repoAndFilters');
    }

    applyFilter(filter, satellites) {
        Settings.setSetting('filter', filter.label);
        const selectedSatellite = SatelliteService.getSelected();
        satellites.filter(filter.func).forEach(s => s.status.on = true);
        satellites.filter(s => !filter.func(s)).forEach(s => {if (s !== selectedSatellite) {s.status.on = false;}});
    }

    handleFilterChange = filter => {
        const {satellites} = this.state;
        this.applyFilter(filter, satellites);
        this.setState({filter}, () => this.setState({showDropdown: false }));
    };

    handleRepoChange = (option) => {
        option.set()
        this.setState({isLoading: true, selectedRepo: option});
    }

    handleSearchChange = event => {
        const search = event.target.value;
        this.setState({search});
    };

    forceUpdate = satellites => {
        this.setState({satellites}, () => {if (this.scrollRef) {this.scrollRef.scrollIntoView({behavior: 'smooth', inline: 'center'});}});
    };

    selectSatellite = satellite => {
        const {satellites} = this.state;
        SatelliteService.select(satellite);
        this.forceUpdate(satellites);
    };

    showDropdown = () => {
        this.setState({showDropdown: true}, () => this.backListener = App.addListener('backButton', () => this.closeDropdown()));
    };

    closeDropdown = () => {
        if (this.backListener) {
            this.backListener.remove();
        }
        this.setState({showDropdown: false}, () => this.setState({showDropdown: false}));
    }

    render() {
        const {satellites, filter, search, isLoading, showDropdown, selectedRepo} = this.state;
        const searchFunction = search ? searchFilter(search) : () => true;
        const selectedSatellite = SatelliteService.getSelected();
        const filteredSatellites = satellites.filter(filter.func).filter(searchFunction);
        filteredSatellites.forEach(s => s.status.on = true);
        const nonMatchedSatellites = satellites.filter(s => !filteredSatellites.includes(s));
        nonMatchedSatellites.forEach(s => {if (s !== selectedSatellite) {s.status.on = false}});
        return (
            <div className="satellite-list-container">
                <div className="select-container">
                    <div className="select-button" onClick={this.showDropdown}>
                        <div className="select-button-label">{filter.label} <span>({satellites.filter(filter.func).length})/({satellites.length})</span></div>
                        {showDropdown && <div className="selector">
                            <div className="selector-header">Satellite Collection</div>
                            {SatelliteService.repos.map(
                                option => (
                                    <div className={`selector-option ${option.status() ? 'selected' : ''}`}
                                    key={option.label}
                                    onClick={() => this.handleRepoChange(option)}>
                                        {option.label} <Radio isOn={option === selectedRepo} />
                                    </div>
                                )
                            )}
                            <div className="selector-header">Filters</div>
                            {SatelliteService.filters.map(
                                (f) => (<div
                                    className={`selector-option ${f === filter ? 'selected' : ''}`}
                                    key={f.label}
                                    onClick={() => this.handleFilterChange(f)}>
                                        {f.label} ({satellites.filter(f.func).length}) <Radio isOn={f === filter} colour="yellow" />
                                    </div>)
                            )}
                            <div className="selector-footer"><button onClick={() => this.closeDropdown()}>Done</button></div>
                        </div>}
                    </div>
                    <input placeholder="Search:\>" type="search" className="search" value={search} onChange={this.handleSearchChange} />
                </div>
                <div className="satellite-row">
                    <div className="satellite-list matched">
                            {
                                filteredSatellites
                                .map(s => (
                                    <div
                                        key={s.id}
                                        ref={ref => {if (s === selectedSatellite) {this.scrollRef = ref;}}}
                                        className={`satellite-list-item ${s === selectedSatellite ? 'selected' : ''} ${s.id.split('-')[0].toLowerCase()}`}
                                    >
                                        <div onClick={() => this.selectSatellite(s)} className="satellite-text">{s.name}</div>
                                    </div>
                                ))
                            }
                            {
                                filteredSatellites.length === 0 && (<div className="satellite-none">
                                    <div className="satellite-text">{filter.error}</div>
                                </div>)
                            }
                    </div>
                </div>
                <Spinner show={isLoading} />
                {showDropdown && <Banner />}
            </div>
        );
    }
}

export default SatelliteList;