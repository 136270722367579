import {metresToKm} from '../services/utils';

const EARTH_RADIUS = 6371;
const NOOP = () => {};

class Satellite {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        this.position = [0,0];
        this.status = {on: false, visible: false, nextVisible: null};
        this._statusUpdater = NOOP; 
    }
    setBright(val) {
        this.bright = val;
        return this;
    }
    setVisibility(val) {
        this.status.on = val;
    }
    setAltitude(alt) {
        this.altitude = alt;
        return this;
    }
    setRealAlt(realAlt) {
        this.altText = realAlt;
        return this;
    }
    setPosition(coords) {
        this.position = coords;
        return this;
    }
    setRadial(radial) {
        this.radial = radial;
        return this;
    }
    setTimeline(path) {
        path.forEach(p => {
            const distInMetres = (Math.abs(p.radial) - EARTH_RADIUS) * 1000
            p.time = new Date(p.time);
            p.altitude = distInMetres;
            p.altText = metresToKm(distInMetres);
        });
        this.path = path;
        return this;
    }
    calcPosition(time) {
        let closestLeft, closestRight;
        for (let i = 0; i< this.path.length; i++) {
            if (this.path[i].time <= time) {
                closestLeft = this.path[i];
            }
            if (this.path[i].time >= time) {
                closestRight = this.path[i];
                break;
            }
        }
        if (!closestLeft || !closestRight) {
            this.setPosition((closestLeft ? closestLeft.point : (closestRight ? closestRight.point : null)))
            .setAltitude((closestLeft ? closestLeft.altitude : (closestRight ? closestRight.altitude : null)))
            .setRealAlt((closestLeft ? closestLeft.altText : (closestRight ? closestRight.altText : null)));
            return;
        }
        if (closestRight.time - closestLeft.time === 0) {
            this.setPosition(closestRight.point)
            .setAltitude(closestRight.altitude)
            .setRealAlt(closestRight.altText);
            return;
        }
        const timeRatio = (time - closestLeft.time) / (closestRight.time - closestLeft.time);
        let lngDiff = (closestRight.point[0] - closestLeft.point[0]);
        if (Math.abs(lngDiff) > 180) {
            const left = lngDiff < 0 ? closestLeft.point[0] - 360 : closestLeft.point[0];
            const right = lngDiff < 0 ? closestRight.point[0] : closestRight.point[0] - 360;
            lngDiff = right - left;
        }
        lngDiff = lngDiff * timeRatio;
        const latDiff = (closestRight.point[1] - closestLeft.point[1]) * timeRatio;
        const altDiff =  (closestRight.altitude - closestLeft.altitude) * timeRatio;
        this.setPosition([closestLeft.point[0] + lngDiff, closestLeft.point[1] + latDiff])
        .setAltitude(closestLeft.altitude + altDiff)
        .setRealAlt(closestLeft.altText)
    }
    calcNextVisible(func) {
        const now = new Date();
        const startPoint = this.path.findIndex(p => p.time >= now);
        for (let i = startPoint; i < this.path.length; i++) {
            if (func([...this.path[i].point, this.path[i].altitude])) {
                //console.log(this.path[i].time);
                this.status.nextVisible = this.path[i].time;
                this._statusUpdater(this.status.nextVisible);
                return;
            }
        }
    }
    onStatusUpdate = func => this._statusUpdater = func;
}

export default Satellite;