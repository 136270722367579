import React, { Component } from 'react';
import { Capacitor, Plugins } from '@capacitor/core';
import Spinner from '../Spinner';
const { AdMob } = Plugins;

export default class Interstital extends Component {
  constructor(props) {
    super(props);
    AdMob.prepareInterstitial({adId: 'ca-app-pub-2197773586929118/9718127638'})
    .catch(err => console.log(err));
  }
  state = {
    loading: true,
  }
  componentDidMount() {
    if (Capacitor.getPlatform() === 'web') {
      this.setState({loading: false}, () => this.props.closeCallback());
      return;
    }
    AdMob.addListener('onInterstitialAdLoaded', (info) => {
      AdMob.showInterstitial();
      this.setState({loading: false}, () => this.props.closeCallback());
    });
    AdMob.addListener('onInterstitialAdFailedToLoad', info => {
      this.setState({loading: false}, () => this.props.closeCallback());
    });
  }
  render() {
    const {loading} = this.state;
    return (<div>
      <Spinner show={loading} />
    </div>)
  }
}