import React, { useState } from 'react';
import './App.css';
import Globe from './components/GlobeCesium';
import SatelliteList from './components/SatelliteList';
import AppFunctions from './components/AppFunctions';
import SatelliteStyler from './components/SatelliteStyler';
import Interstital from './components/ads/Interstital';
import ObjectStatus from './components/ObjectStatus';
import { Plugins } from '@capacitor/core';
const { AdMob } = Plugins;

function App() {
  const [showingAd, setShowingAd] = useState(false);
  AdMob.initialize({
    requestTrackingAuthorization: false,
    testingDevices: ['10bc9624-1607-4652-bf0b-d95ae0fa1ddc', '7e3a011f-d54a-4c3c-8362-be8690246f6d']
  }).catch((err) => console.log(err));
  return (
    <div className="App">
      <div className="earth-container">
        <Globe />
      </div>
      <ObjectStatus />
      <div className="app-controls">
        <SatelliteList />
        <AppFunctions />
      </div>
      <SatelliteStyler rootStyle=".satellite-list-item" />
      <ul className="menu absolute-positioned">
          <li><div className="ad-support-button" onClick={() => setShowingAd(true)}>Support this app: Watch Ad</div></li>
        </ul>
        {showingAd && <Interstital closeCallback={() => setShowingAd(false)} />}
    </div>
  );
}

export default App;
