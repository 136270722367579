import {degreesToRadians} from '../services/utils';
import { Plugins } from '@capacitor/core';
const { Geolocation, Motion, Toast } = Plugins;

const GLOBE_MODE = 'globe', POV_MODE = 'POV';

const LocationService = () => {
    const location = [0,0,5], listeners = [], bearingChangers = [], pickWatchers = [];
    let status = null,
        geoPosition = null,
        detach,
        goHome,
        mode = GLOBE_MODE,
        heading = null,
        pitch = null,
        LIVE_BEARINGS = false,
        isPicking = false,
        canBearings = false;

    const setLocation = position => {
        geoPosition = position;
        location[0]= position.coords.longitude;
        location[1] = position.coords.latitude;
        location[2] = position.coords.altitude || 1;
        if(position.coords.heading !== null) {
            heading = degreesToRadians(position.coords.heading);
        }
        status = true;
        listeners.forEach(l => l(location));
    };

    function deviceOrientationHandler (e) {
        canBearings = true;
        if (LIVE_BEARINGS) {
            heading = degreesToRadians(360 - e.alpha);
            pitch = degreesToRadians(e.beta-90)
            bearingChangers.forEach(bC => bC(heading, pitch));
        }
    }

    const errHandle = err => console.log(err);
    Geolocation.getCurrentPosition().then(setLocation, errHandle);
    Motion.addListener('orientation', deviceOrientationHandler, errHandle);
    
    return {
        get: () => location,
        getGeoPosition: () => geoPosition,
        status: () => status,
        notify: listener => status ? listener(location) : listeners.push(listener),
        registerHome: f => goHome = f,
        goHome: () => {mode = POV_MODE; goHome();},
        publish: setLocation,
        registerDetach: f => detach = f,
        detach: () => {mode = GLOBE_MODE; detach();},
        registerOnBearingChange: f => bearingChangers.push(f),
        setBearing: (bearing, _pitch) => {heading = bearing; pitch = _pitch; bearingChangers.forEach(bC => bC(bearing, pitch));},
        getMode: () => mode,
        watchLiveBearings: () => LIVE_BEARINGS = true,
        unwatchLiveBearings: () => LIVE_BEARINGS = false,
        getLiveBearingsMode: () => LIVE_BEARINGS,
        bearingsAreAvailable: () => canBearings,
        getBearings: () => heading,
        getPitch: () => pitch,
        getPicking: () => isPicking,
        setPicking: val => {
            if (val) {
                Toast.show({text: 'Tap your location on the globe...', duration: 'short'});
            }
            isPicking = val;
            pickWatchers.forEach(f => f(val));
        },
        registerPick: func => pickWatchers.push(func),
        MODES: {GLOBE_MODE, POV_MODE}
    };
}

export default LocationService();