import React, { Component } from 'react';
import Banner from '../ads/Banner';
import './about.css'
import { Plugins } from '@capacitor/core';
const { App } = Plugins;

class About extends Component {

  componentDidMount() {
    this.backListener = App.addListener('backButton', () => this.props.closer())
  }

  componentWillUnmount() {
    this.backListener.remove();
  }

  render() {
    const {closer} = this.props;
      return (
        <div className="about-container">
            <div className="about-stuff">
              <h2>What is Find Satellites?</h2>
              <p>It&rsquo;s an app to help you find satellites! It can show you where they are around the globe, or from your location on Earth.</p>
              <p>The app plots the positions of 220+ significant satellites such as the ISS, geostationary satellites, cloud and weather monitors and of course the Moon. On top of that, it tracks the current 1300+ (and counting!) Starlink satellites.</p>
              <p>These positions are plotted relative to the Earth, based on a longitude, latitude and radial distance.</p>
              <p>Each satellite is accompanied by a line showing its current trajectory, within a two hour window, more or less. A line also joins the satellite&rsquo;s position in space to its overhead position on the Earth, showing in green when in your line of sight, and red when not.</p>
              <p>The paths you see are based on lat / long positions, so <strong>observer-relative</strong>. This means that geo-stationary satellites won't appear to have any paths drawn, because they are always going to be at the same place relative to Earth. I may implement the ability to change to different reference frames in future.</p>
              <p>The first time you use the app, it should request permission to use your device&rsquo;s location. This is so that your position can be plotted on the globe, and used to calculate satellite visibility. If you don't want to grant such permission, or just want to pretend you are somewhere else on Earth, just use the Pick Location button to select a new location on the Earth.</p>
              <p>The theoretical visibility of satellites is determined by whether a straight line can be cast between your position on the Earth&rsquo;s surface and the satellite without intersecting the curvature of the Earth. At the moment, this may not reflect actual visibility as nearby hills, trees and nefarious malcontents may not be fully taken into consideration.</p>
              <p>Data available for each satellite will generally span around 2 hours, so you will see each satellites positions plotted as a path, showing the recent past and imminent future, generally within a two hour window. When a satellite is selected, an arrow on the path will indicate direction of travel.</p>
              <p>The app shows a row of satellites matching your current filter selection. Possible selections include currently visible satellites, near-earth satellites, far-earth satellites, geo-stationary satellites, and so forth.</p>
              <h2>Why do some satellites not show path information?</h2>
              <p>One explanation is that you&rsquo;re looking at the geostationary satellites. Since these move at the same speed as the Earth, they will always appear relative to the same latitude and longitude point. The other possibility is that you&rsquo;re looking at the Starlink satellites. If the app plotted all the Starlink paths, the Earth would just look like a giant ball of string, so a Starlink path only shows when you select one.</p>
              <h2>Speaking of Starlinks, I don't see any. What's going on?</h2>
              <p>The top left area of the app shows you the current selected satellite collection. If it doesn't say Starlink or All, click there to change it.</p>
              <h2>What about the Starlink trains?</h2>
              <p>Use the cluster filter - that filter works by identifying satellites which are close to other satellites. This will find any Starlinks orbiting in close train formation. It will also throw up a few false positives - just ignore these!</p>
              <h2>Where is the satellite position data coming from?</h2>
              <p>Data is compiled from a feed provided by NASA,and additional TLE data from Celestrak. These feeds are fetched and processed remotely, and the app fetches the current position data only when you use it. If you have the app open for long enough, it will fetch new data automatically, but if you need or want to do this manually at any point, just press the Reload Data button.</p>
              <h2>Something broke, when will you fix it?</h2>
              <p>I&rsquo;ve probably noticed, and so hopefully soon. But feel free to use the developer contact details on Google Play to harass me about it!</p>
              <Banner />
              <div className="selector-footer"><button onClick={closer}>Back</button></div>
            </div>
          </div>
      );
  }
}

export default About;