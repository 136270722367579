import React, { Component } from 'react';
import './Spinner.css'

class Spinner extends Component {
  render() {
    const {show} = this.props;
    if (show) {
      return (
        <div className="spinner-container">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )
    }
    return null;
  }
}

export default Spinner;
