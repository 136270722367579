import React, { Component } from 'react';
import {singleOrPlural} from './../services/utils';

class TimeShower extends Component {

    state = {
        nextVisible: false,
    }

    componentDidMount() {
        this._intervalRecord = setInterval(() => {
            if (this.props.satellite) {
                this.setState({nextVisible: this.props.satellite.status.nextVisible });
            }
        }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this._intervalRecord);
    }

    render() {
        const {satellite} = this.props;
        const {nextVisible} = this.state;
        const now = new Date();
        const timeToNext = nextVisible && nextVisible > now ? nextVisible - now : false;
        let nextVisibleText;
        if (timeToNext) {
            const minutes = Math.floor(timeToNext / 60000), seconds = Math.floor(timeToNext / 1000);
            nextVisibleText = timeToNext < 60000 ? `${seconds} ${singleOrPlural('second', seconds)}` : `${minutes} ${singleOrPlural('minute', minutes)}, ${Math.round(timeToNext%60000 / 1000)} seconds`;
        } 
        return (
            <span>
                {satellite.status.visible ? 'Currently in your line of sight. ' : 'Not currently in line of sight. '}
                {(!satellite.status.visible && nextVisible) && <span>Next in your line of sight in approx <strong>{nextVisibleText}</strong></span>}
                {(!satellite.status.visible && !nextVisible) && 'Not due to be in your line of sight over the next hour or two.'}
            </span>
        );
    }
}

export default TimeShower;