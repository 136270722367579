import Satellite from '../models/Satellite';
import Settings from './settings-service';
import compileElements from './orbital-element-service';
import { Plugins } from '@capacitor/core';
const { Toast } = Plugins;

const satelliteClusters = [];

const trainFinder = satellites => {
    satelliteClusters.length = 0;
    const satelliteGrid = {};
    satellites.forEach(s => {
        const ref = `${Math.round(s.position[0] / 1.5)}:${Math.round(s.position[1] / 1.5)}`;
        satelliteGrid[ref] = satelliteGrid[ref] ? satelliteGrid[ref] : [];
        satelliteGrid[ref].push(s);
    });
    Object.keys(satelliteGrid).forEach(key => {
        if (satelliteGrid[key].length > 1) {
            satelliteClusters.push(...satelliteGrid[key]);
        }
    });
}

const satRepos = {all: 'satellite-data.json', base: 'norad-nasa-data.json', starlink: 'starlink-data.json'/*, nearEarthObjects: 'near-earth-objects.json'*/};
let selectedRepo = satRepos.base;

const SatelliteService = () => {
    let dataRetrieved = false;
    let connectionError = false;
    const satellites = [
        
    ];
    const waiters = [];
    const onSelecters = [];
    const subscribers = [];
    const errorHandlers = [];

    let now = new Date();
    let selectedSatellite = null;
    let satelliteDescriptions = [];

    let reloadTimeout;

    const EMPTY_DESCRIPTION = {description: "No further information available.", photos: [], icon: 'satellite.png'};

    function registerOnSelect(func) {
        onSelecters.push({func});
    }

    function handleConnectionError(err) {
        console.log(err);
        connectionError = true;
        Toast.show({text: 'Could not load data. Check internet connection and reload...', duration: 'long'});
        errorHandlers.forEach(f => f());
    }

    function getSatelliteDescriptions(next) {
        fetch('/data/metadata/satellite-list.json', {method: 'GET', mode: 'cors', headers: {Accept: 'application/json'}})
        .then(response => {
            response.json().then(data => {
                satelliteDescriptions = data;
                satelliteDescriptions.forEach(s => {
                    s.photos = s.photos || [];
                    s.icon = s.icon || 'satellite.png';
                });
                next();
            })
        })
    }

    function getSatelliteLocations() {
        if (reloadTimeout) {
            clearTimeout(reloadTimeout);
        }
        connectionError = false;
        const cacheBuster = new Date().valueOf();
        satellites.length = 0;
        fetch(`https://www.find-satellites.com/data/${selectedRepo}?a=${cacheBuster}`, {
        /*fetch(`/data/${selectedRepo}?a=${cacheBuster}`, {*/
            method: 'GET', mode: 'cors', headers: {Accept: 'application/json'}
        }).then(response => {
            now = new Date();
            response.json().then(data => {
                /*if (selectedRepo === satRepos.nearEarthObjects) {
                    data.forEach(compileElements);
                    console.log(data);
                }*/
                data.forEach(d => {
                    if (d.id === 'sun' || d.id === 'moon') {
                        return;
                    }
                    const satellite = new Satellite(d.id, d.name);
                    satellite
                    .setBright(d.bright)
                    .setTimeline(d.path)
                    .calcPosition(now);
                    satellites.push(satellite);
                });
                waiters.forEach(f => f(satellites));
                dataRetrieved = true;
                trainFinder(satellites);
                reloadTimeout = setTimeout(getSatelliteLocations, 1000 * 60 * 30);
                if (selectedSatellite) {
                    const newSelected = satellites.find(s => s.id === selectedSatellite.id);
                    if (newSelected) {
                        satelliteServiceSelect(newSelected);
                    }
                }
            });
        }).catch(handleConnectionError)
    }

    function init() {
        Settings.getSetting('repo').then(repo => {
            if (!repo) {
                Settings.setSetting('repo', selectedRepo);
            } else {
                selectedRepo = repo;
            }
            getSatelliteDescriptions(getSatelliteLocations);
            },
            () => {
                Settings.setSetting('repo', selectedRepo);
                getSatelliteDescriptions(getSatelliteLocations);
            }
        );
    }

    function refresh() {
        Settings.getSetting('repo').then(repo => {
            if (!repo) {
                Settings.setSetting('repo', selectedRepo);
            } else {
                selectedRepo = repo;
            }
            getSatelliteLocations();
            },
            () => {
                Settings.setSetting('repo', selectedRepo);
                getSatelliteDescriptions(getSatelliteLocations);
            }
        );
    }

    const satelliteServiceSelect = (satellite, key) => {
        if (satellite === selectedSatellite || !satellite || !satellite.status) {
            selectedSatellite = null;
        } else {
            selectedSatellite = satellite;
            selectedSatellite.status.on = true;
        }
        onSelecters.forEach(os => os.func(selectedSatellite, key));
    }

    init();

    const observers = {};

    return {
        refresh,
        get: () => {
            return new Promise((resolve, reject) => {
                waiters.push(resolve);
                if (dataRetrieved) {
                    resolve(satellites);
                }
            })
        },
        getSubscribe: (func, errHandle) => {
            waiters.push(func);
            if (errHandle) {
                errorHandlers.push(errHandle);
            }
            if (dataRetrieved) {
                func(satellites);
            }
            if (connectionError) {
                errHandle();
            }
        },
        register: (func, key) => observers[key] = func,
        deregister: key => delete observers[key],
        select: satelliteServiceSelect,
        getSelected: () => selectedSatellite,
        onSelect: registerOnSelect,
        broadcast: () => subscribers.forEach(func => func(satellites)),
        subscribe: func => subscribers.push(func),
        repos: [
            {label: 'All', status: () => selectedRepo === satRepos.all, set: () => Settings.setSetting('repo', satRepos.all).then(refresh)},
            {label: 'NASA & NORAD', status: () => selectedRepo === satRepos.base, set: () => Settings.setSetting('repo', satRepos.base).then(refresh)},
            {label: 'Starlink', status: () => selectedRepo === satRepos.starlink, set: () => Settings.setSetting('repo', satRepos.starlink).then(refresh)},
            /*{label: 'Near Earth Asteroids', status: () => selectedRepo === satRepos.nearEarthObjects, set: () => Settings.setSetting('repo', satRepos.nearEarthObjects).then(refresh)},*/
        ],
        filters: [
            {label: 'Line of sight', func: s => s.status.visible, error: 'No satellites currently in line of sight from your location.'},
            {label: 'Line of sight near', func: s => s.status.visible && s.altitude < 2000000, error: 'No close-by satellites in line of sight.'},
            {label: 'High orbit', func: s => s.altitude >= 35000000, error: 'No high earth satellite data available.'},
            {label: 'Medium orbit', func: s => s.altitude >= 2000000 && s.altitude < 35000000, error: 'No medium earth satellite data available.'},
            {label: 'Low orbit', func: s => s.altitude < 2000000, error: 'No low earth satellite data available.'},
            {label: 'ISS', func: s => s.id === 'iss', error: 'ISS position not currently available'},
            {label: 'Clusters', func: s => satelliteClusters.includes(s), error: 'No clusters identified in current set.'},
            {label: 'All satellites', func: s => true, error: 'No satellite data available.'}
        ],
        getDescription: id => satelliteDescriptions.find(d => d.id === id) ||
                             (id.toLowerCase().includes('starlink') ? satelliteDescriptions.find(d => d.id === 'starlink') : EMPTY_DESCRIPTION),
    }
}

export default SatelliteService();