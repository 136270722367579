import React, { Component } from 'react';
import './Radio.css';

class Radio extends Component {

    render() {
        const {isOn, colour} = this.props;
        return (
            <div className={`radio ${isOn ? 'on' : 'off'} ${colour}`} />
        );
    }
}

export default Radio;