import React, { Component } from 'react';
import './SatelliteInfo.css';
import TimeShower from './TimeShower';
import PositionShower from './PositionShower';
import SatelliteService from '../services/satellite-service';
import Interstital from './ads/Interstital';
import UiService from '../services/ui-link-service';
import About from './content/about';

class SatelliteInfo extends Component {

    state = {
        showingAd:false,
        showingAbout: false,
    };

    reloadData() {
        SatelliteService.refresh();
    }

    activateInterstital = () => {
        this.setState({showingAd: true});
    }
    concludeInterstital = () => {
        this.setState({showingAd: false});
    }

    accessDropDown = () => {
        UiService.use('repoAndFilters', []);
    }

    showAbout = () => {
        this.setState({showingAbout: true})
    }

    hideAbout = () => {
        this.setState({showingAbout: false});
    }

    render() {
        const {satellite} = this.props;
        const { showingAd, showingAbout } = this.state;
        
        return (
            <div className="satellite-info">
                {satellite ? 
                    (<div className="satellite-info-container">
                        <div className="main-satellite-details">
                            <div className="satellite-details-data">
                                <div className="satellite-photos">
                                    {SatelliteService.getDescription(satellite.id).photos.map(photo => <img key={photo} alt={satellite.name} src={`/images/satpics/${photo}`} />)}    
                                </div>
                                <h3>{satellite.name}</h3>
                                <PositionShower satellite={satellite} />
                                <p><strong>Visibility: </strong>
                                    <TimeShower satellite={satellite} />
                                </p>
                                <h4>Description</h4>
                                <p className="description" dangerouslySetInnerHTML={{
                                    __html: SatelliteService.getDescription(satellite.id).description
                                }} />
                            </div>
                        </div>
                    </div>) :
                    (<span>
                        <h3>No satellite selected...</h3>
                        <button className="reload-button" onClick={this.accessDropDown}>Change satellite collection and filters</button>
                    </span>)
                }
                <div className="ad-support-button" onClick={this.activateInterstital}>Watch ad to support this app</div>
                <button className="reload-button ad-support-button" onClick={this.showAbout}>About this app</button>
                <button className="reload-button" onClick={this.reloadData}>Reload Data</button>
                {showingAd && <Interstital closeCallback={this.concludeInterstital} />}
                {showingAbout && <About closer={this.hideAbout} />}
            </div>
        );
    }
}

export default SatelliteInfo;