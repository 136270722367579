import React, { Component } from 'react';
import './BearingIndicator.css';
import LocationService from '../services/location-service';
import {radiansToDegreesHeading, formatNumber} from '../services/utils';

const toTwoPlaces = formatNumber(2, true);

class BearingIndicator extends Component {
    state = {
        degrees: 0,
        direction: 'N',
        pitch: 0,
    }

    componentDidMount() {
        LocationService.registerOnBearingChange((bearing, pitch) => {
            const state = radiansToDegreesHeading(bearing, pitch);
            this.setState(state);
        })
    }

    render() {
        const {degrees, direction, pitch} = this.state;
        return (
            <div className="bearing-indicator">
                <div className="content">{toTwoPlaces(degrees)}° {direction} | Pitch: {toTwoPlaces(pitch)}°</div>
            </div>
        );
    }
}

export default BearingIndicator;