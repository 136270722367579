import PlanetService from './planet-service';
import SatelliteService from './satellite-service';
const Cesium = window.Cesium;

function createPositionAltitudeProperty(path, interpolationAlgorithm, coordCreator, referenceFrame) {
    coordCreator = coordCreator || (p => Cesium.Cartesian3.fromDegrees(...p.point, p.altitude));
    referenceFrame = referenceFrame || Cesium.ReferenceFrame.FIXED;
    const prop = new Cesium.SampledPositionProperty(referenceFrame);
    if (interpolationAlgorithm) {
      prop.setInterpolationOptions({interpolationAlgorithm, interpolationDegree: 2});
    }
    path.forEach(p => prop.addSample(Cesium.JulianDate.fromDate(p.time), coordCreator(p)));
    //console.log(coordCreator(path[0]));
    return prop;
}
function createPositionGroundProperty(path, coordCreator, referenceFrame) {
    coordCreator = coordCreator || (p => Cesium.Cartesian3.fromDegrees(...p.point, 0));
    referenceFrame = referenceFrame || Cesium.ReferenceFrame.FIXED;
    const prop = new Cesium.SampledPositionProperty(referenceFrame);
    path.forEach(p => prop.addSample(Cesium.JulianDate.fromDate(p.time), coordCreator(p)));
    return prop;
}
const getAnchorId =(s) => `${s.id}-_anchor`;

const distanceDisplay = new Cesium.DistanceDisplayCondition(0, Number.POSITIVE_INFINITY);

function getSatelliteEntityTemplate() {
    const template = {
      planet: (p, viewer) => {
        const meta = PlanetService.getDetails(p.name);
        viewer.entities.removeById(p.id);
        const coordCreator = p => {
          let pos = new Cesium.Cartesian3(...p.point);
          const fixedToIcrf = Cesium.Transforms.computeIcrfToFixedMatrix(Cesium.JulianDate.fromDate(p.time), new Cesium.Matrix3());
          if (Cesium.defined(fixedToIcrf)) {
            return Cesium.Matrix3.multiplyByVector(fixedToIcrf, pos, new Cesium.Cartesian3() )
          } else {
            return pos;
          }
        };
        //const gCreator = p => new Cesium.Cartesian3(0,0,0);
        const planetPosition = createPositionAltitudeProperty(p.path, Cesium.LagrangePolynomialApproximation, coordCreator);
        //const groundPosition = createPositionGroundProperty(p.path, gCreator);
        const entity = viewer.entities.add({
          id: p.id,
          name: p.name,
          availability: new Cesium.TimeIntervalCollection([
            new Cesium.TimeInterval({
                start: Cesium.JulianDate.fromDate(p.path[0].time),
                stop: Cesium.JulianDate.fromDate(p.path[p.path.length-1].time),
            }),
          ]),
          position: planetPosition,
          billboard : {
            image : `images/planets/${meta.icon}`,
            width : 32,
            height : 32,
          },
          label : {
            text : p.name,
            font : '14pt monospace',
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            outlineWidth : 2,
            verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
            pixelOffset : new Cesium.Cartesian2(0, -12),
          },
          /*viewFrom: new Cesium.Cartesian3(0.0, 0.0, 100000),*/
          path: {
            resolution: 30,
            material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOWGREEN),
            width: 6,
          },
          show: p.status.on
        });
        /*const anchorId = getAnchorId(p);
        viewer.entities.removeById(anchorId);
        p.model.anchor = viewer.entities.add({
            id: anchorId,
            availability: new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: Cesium.JulianDate.fromDate(p.path[0].time),
                stop: Cesium.JulianDate.fromDate(p.path[p.path.length-1].time)
              }),
            ]),
            polyline: {
              positions : new Cesium.PositionPropertyArray([groundPosition, planetPosition]),
              material: Cesium.Color.BLUEVIOLET,
              width: 1,
              arcType: Cesium.ArcType.NONE,
            },
            parent: entity,
          });*/
        return entity;
      },
      default: (s, viewer) => {
        const meta = SatelliteService.getDescription(s.id);
        viewer.entities.removeById(s.id);
        const satellitePosition = createPositionAltitudeProperty(s.path, Cesium.LagrangePolynomialApproximation);
        const groundPosition = createPositionGroundProperty(s.path);
        const entity = viewer.entities.add({
          id: s.id,
          name: s.name,
          availability: new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                  start: Cesium.JulianDate.fromDate(s.path[0].time),
                  stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time),
              }),
            ]),
          position: satellitePosition,
          billboard : {
              image : `images/saticons/${meta.icon}`,
              width : 42,
              height : 42,
            },
          label : {
              text : s.name,
              font : '14pt monospace',
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              outlineWidth : 2,
              verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
              pixelOffset : new Cesium.Cartesian2(0, -9),
          },
          /*viewFrom: new Cesium.Cartesian3(0.0, 0.0, 100000),*/
          path: {
              resolution: 30,
              material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.SILVER),
              width: 6,
              show: false,
            },
          show: s.status.on
        });
        const anchorId = getAnchorId(s);
        viewer.entities.removeById(anchorId);
        s.model.anchor = viewer.entities.add({
            id: anchorId,
            availability: new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: Cesium.JulianDate.fromDate(s.path[0].time),
                stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time)
              }),
            ]),
            polyline: {
              positions : new Cesium.PositionPropertyArray([groundPosition, satellitePosition]),
              material: Cesium.Color.RED,
              width: 1,
              arcType: Cesium.ArcType.NONE,
            },
            parent: entity,
        });
        return entity;
      },
      sun: (s, viewer) => {
        viewer.entities.removeById(s.id);
        const satellitePosition = createPositionAltitudeProperty(s.path);
        const groundPosition = createPositionGroundProperty(s.path);
        const entity = viewer.entities.add({
          id: s.id,
          name: s.name,
          availability: new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: Cesium.JulianDate.fromDate(s.path[0].time),
                stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time),
              }),
            ]),
          position: satellitePosition,
          point: {
              pixelSize : 10,
              color : Cesium.Color.YELLOW,
              outlineColor : Cesium.Color.ORANGE,
              outlineWidth : 4
          },
          label : {
              text : s.name,
              font : '14pt monospace',
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              outlineWidth : 2,
              verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
              pixelOffset : new Cesium.Cartesian2(0, -9)
          },
          /*om: new Cesium.Cartesian3(0.0, 0.0, 1000000),*/
          path: {
              resolution: 30,
              material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.SILVER),
              width: 6
          },
          show: s.status.on,
        });
        const anchorId = getAnchorId(s);
        viewer.entities.removeById(anchorId);
        s.model.anchor = viewer.entities.add({
          id: anchorId,
          availability: new Cesium.TimeIntervalCollection([
            new Cesium.TimeInterval({
              start: Cesium.JulianDate.fromDate(s.path[0].time),
              stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time)
            }),
          ]),
          polyline: {
            positions : new Cesium.PositionPropertyArray([groundPosition, satellitePosition]),
            material: Cesium.Color.RED,
            width: 1,
            arcType: Cesium.ArcType.NONE,
          },
          parent: entity
        });
        return entity;
      },
      moon: (s, viewer) => {
          viewer.entities.removeById(s.id);
          const satellitePosition = createPositionAltitudeProperty(s.path);
          const groundPosition = createPositionGroundProperty(s.path);
          const entity = viewer.entities.add({
            id: s.id,
            name: s.name,
            availability: new Cesium.TimeIntervalCollection([
                new Cesium.TimeInterval({
                  start: Cesium.JulianDate.fromDate(s.path[0].time),
                  stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time),
                }),
              ]),
            position: satellitePosition,
            point: {
                pixelSize : 5,
                color : Cesium.Color.GRAY,
                outlineColor : Cesium.Color.WHITE,
                outlineWidth : 2
            },
            label : {
                text : s.name,
                font : '14pt monospace',
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                outlineWidth : 2,
                verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
                pixelOffset : new Cesium.Cartesian2(0, -9)
            },
            /*viewFrom: new Cesium.Cartesian3(0.0, 0.0, 100000.0),*/
            path: {
                resolution: 30,
                material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.SILVER),
                width: 6
            },
            show: s.status.on,
        });
        const anchorId = getAnchorId(s);
        viewer.entities.removeById(anchorId);
        s.model.anchor = viewer.entities.add({
          id: anchorId,
          availability: new Cesium.TimeIntervalCollection([
            new Cesium.TimeInterval({
              start: Cesium.JulianDate.fromDate(s.path[0].time),
              stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time)
            }),
          ]),
          polyline: {
            positions : new Cesium.PositionPropertyArray([groundPosition, satellitePosition]),
            material: Cesium.Color.RED,
            width: 1,
            arcType: Cesium.ArcType.NONE,
          },
          parent: entity
        });
        return entity;
      },
      starlink: (s, viewer) => {
        viewer.entities.removeById(s.id);
        const satellitePosition = createPositionAltitudeProperty(s.path, Cesium.LagrangePolynomialApproximation);
        const groundPosition = createPositionGroundProperty(s.path);
        const entity = viewer.entities.add({
          id: s.id,
          name: s.name,
          availability: new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                  start: Cesium.JulianDate.fromDate(s.path[0].time),
                  stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time),
              }),
            ]),
          position: satellitePosition,
          billboard : {
            image : 'images/saticons/starlink.png',
            width : 42,
            height : 24
          },
          label : {
              text : s.name.replace('STARLINK-', 'SL'),
              font : '8pt monospace',
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              outlineWidth : 1,
              verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
              pixelOffset : new Cesium.Cartesian2(0, -9)
          },
          path: {
            resolution: 30,
            material: Cesium.Color.SILVER,
            width: 2,
            show: false
          },
          /*om: new Cesium.Cartesian3(0.0, 0.0, 10000.0),*/
          show: s.status.on
        });
        const anchorId = getAnchorId(s);
        viewer.entities.removeById(anchorId);
        s.model.anchor = viewer.entities.add({
            id: anchorId,
            availability: new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: Cesium.JulianDate.fromDate(s.path[0].time),
                stop: Cesium.JulianDate.fromDate(s.path[s.path.length-1].time)
              }),
            ]),
            polyline: {
              positions : new Cesium.PositionPropertyArray([groundPosition, satellitePosition]),
              material: Cesium.Color.RED,
              width: 1,
              arcType: Cesium.ArcType.NONE,
            },
            parent: entity,
        });

        return entity;
      }
    };

    return (satellite, viewer) => template[satellite.id] ? template[satellite.id](satellite, viewer) : 
        satellite.id.includes('STARLINK') || satellite.id.includes('FALCON') ? template.starlink(satellite, viewer) :
        satellite.id.includes('planet-') ? template.planet(satellite, viewer) : template.default(satellite, viewer);
}

export default getSatelliteEntityTemplate();