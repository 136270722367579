import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const settingsService = () => {

  return {
    getSetting: key => new Promise((resolve, reject) => {
      Storage.get({key}).then(ret => {
        try {
          resolve(ret.value);
        } catch(e) {
          reject();
        }
      })
    }),
    setSetting: (key, value) => {
      return Storage.set({key, value});
    }
  }
}

export default settingsService();
