const RADIANS = Math.PI * 2, toDegreesMultiplier = 360 / RADIANS;

const AUinMetres = 149597870700;

const formatNumber = (places, pad) => number => !pad ? parseFloat(number.toFixed(places)).toLocaleString() : number.toFixed(places);
const metresToKm = val => `${formatNumber(2)(val / 1000)}km`;

const radiansToDegreesHeading = (bearing, pitch) => {
    const degrees = bearing * toDegreesMultiplier;
    pitch = pitch * toDegreesMultiplier;
    const direction =
        degrees >= 337.5 || degrees < 22.5 ? 'N' :
        degrees < 67.5 ? 'NE' :
        degrees < 112.5 ? 'E' :
        degrees < 157.5 ? 'SE' :
        degrees < 202.5 ? 'S' :
        degrees < 247.5 ? 'SW' :
        degrees < 292.5 ? 'W' :
        'NW';
    return {degrees, direction, pitch};
};

const degreesToRadians = val => val * (Math.PI / 180);

const radiansToDegrees = val => val * toDegreesMultiplier;

const searchFilter = term => s => s.name.toLowerCase().includes(term.toLowerCase());

const singleOrPlural = (term, value) => value === 1 ? term : `${term}s`;

export {AUinMetres, formatNumber, metresToKm, radiansToDegreesHeading, degreesToRadians, searchFilter, singleOrPlural, radiansToDegrees};