import React, { Component } from 'react';
import PlanetService from '../services/planet-service';
import SatelliteService from '../services/satellite-service';
import UiService from '../services/ui-link-service';
import Radio from './ui/Radio';
import './ObjectStatus.css';

class ObjectStatus extends Component {

  state = {
    satelliteRepo: SatelliteService.repos.find(r => r.status()),
    planetsOn: PlanetService.planetsAreShowing(),
  }

  componentDidMount() {
    SatelliteService.getSubscribe(() => {
      const satelliteRepo = SatelliteService.repos.find(r => r.status());
      this.setState({satelliteRepo});
    });
  }

  accessDropDown = () => {
    UiService.use('repoAndFilters', []);
  }

  togglePlanets = () => {
    PlanetService.togglePlanets();
    this.setState({planetsOn: PlanetService.planetsAreShowing()})
  }

  render() {
    const {satelliteRepo, planetsOn} = this.state;
    return (
      <div className="object-status">
        <div className="planet-toggle" onClick={this.togglePlanets}>
          <span>Solar bodies: </span><Radio isOn={planetsOn} colour="yellow" />
        </div>
        <div className="selected-repo select-button" onClick={this.accessDropDown}>
          <span className="select-button-label"> </span><span>{satelliteRepo.label}</span>
        </div>
      </div>
    );
  }
}

export default ObjectStatus;