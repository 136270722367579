import React, {Component} from 'react';
import LocationService from '../services/location-service';
import './Globe.css';
import SatelliteService from '../services/satellite-service';
import PlanetService from '../services/planet-service';
import createSatelliteEntity from '../services/cesium-templates';
import * as qs from 'query-string';
import {degreesToRadians} from '../services/utils';

const Cesium = window.Cesium;

const J2000Epoch = 2451545.0
const JulYear = 365.25
const JulCent = JulYear * 100

const TRACKINGKEY = 'cesium';

const FRAME_RATE = 25;
const ZOOM = 1000000;

const arrowIds = ['north-arrow', 'north-label', 'south-arrow', 'south-label', 'east-arrow', 'east-label', 'west-arrow', 'west-label'];

function checkGlobeIntersection(viewer, p0, p1) {
    if (!p0 || !p1) {return false;}
    let direction = new Cesium.Cartesian3();
    direction = Cesium.Cartesian3.subtract(p1, p0, direction);
    const ray = new Cesium.Ray(p0, direction);
    return !!Cesium.IntersectionTests.rayEllipsoid(ray, viewer.scene.globe.ellipsoid);
}

function getNextVisible(viewer, s, user) {
    let t = Cesium.JulianDate.addSeconds(viewer.clock.currentTime, 30, new Cesium.JulianDate());
    let count = 0;
    while (s.model.entity.availability.contains(t)) {
        if (!checkGlobeIntersection(viewer, user.position.getValue(), s.model.entity.position.getValue(t))) {
            return Cesium.JulianDate.toDate(t);
        }
        t = Cesium.JulianDate.addSeconds(t, 30, t);
        if (++count > 120) {
            console.log('bombed');
            return false;
        } 
    }
    return false;
}

function flyToEntity(viewer, entity, doNotTrack) {
    const destination = viewer.scene.mapProjection.ellipsoid.cartesianToCartographic(
        entity.position.getValue(Cesium.JulianDate.addSeconds(viewer.clock.currentTime, 2, new Cesium.JulianDate()))
    )
    destination.height = destination.height + ZOOM
    const cameraPosition = viewer.scene.mapProjection.ellipsoid.cartographicToCartesian(
        destination
    )
    viewer.trackedEntity = doNotTrack ? null : entity
    viewer.scene.camera.flyTo(
        {
            destination: cameraPosition,
            duration: 2,
        }
    );
}

const selectedPathMaterial = new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW);
const unSelectedPathMaterial = new Cesium.PolylineArrowMaterialProperty(Cesium.Color.SILVER);

class Globe extends Component {
    constructor(props) {
        super(props);
        this.home = [0,0,2];
        const params = qs.parse(window.location.search);
        this.selectedSatellite = params.s ? params.s : false;
    }
    componentDidMount() {
        Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_TOKEN;
        const viewer = new Cesium.Viewer('cesiumGlobe', {
            animation: false,
            baseLayerPicker: false,
            imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
                url:
                  "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/",
              }),
            timeline: false,
            sceneModePicker: false,
            homeButton: false,
            fullscreenButton: false,
            scene3DOnly: true,
            /*creditContainer: 'cesium-attribution',*/
            navigationHelpButton: false,
            geocoder: false,
            infoBox: false,
            shouldAnimate: true,
            targetFrameRate: FRAME_RATE,
        });
        //viewer.scene.moon = new Cesium.Moon();
        viewer.scene.globe.enableLighting = true; 
        viewer.scene.globe.showGroundAtmosphere = false;
        //viewer.scene.logarithmicDepthBuffer = false;
        viewer.scene.globe.depthTestAgainstTerrain = true;
        viewer.camera.defaultZoomAmount = 10000000;
        viewer.camera.frustum.far = Number.MAX_VALUE;
        const user = new Cesium.Entity({
            id: 'you',
            name: 'You',
            position: Cesium.Cartesian3.fromDegrees(...this.home),
            point : {
                pixelSize : 5,
                color : Cesium.Color.RED,
                outlineColor : Cesium.Color.WHITE,
                outlineWidth : 2
            },
            label : {
                text : 'YOU',
                font : '14pt monospace',
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                outlineWidth : 2,
                verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
                pixelOffset : new Cesium.Cartesian2(0, -9)
            },
            viewFrom: new Cesium.Cartesian3(0.0, 0.0, 10000000)
        });
        
        viewer.entities.add(user);

        LocationService.notify(location => {
            this.home = location;
            user.position = Cesium.Cartesian3.fromDegrees(...location);
            arrowIds.forEach(id => viewer.entities.removeById(id));
            viewer.entities.add({
                id: 'north-arrow',
                name: 'north',
                polyline : {
                    positions : Cesium.Cartesian3.fromDegreesArrayHeights([...location, location[0], location[1] + 2, location[2]]),
                    width : 6,
                    material : new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW)
                }
            });
            viewer.entities.add({
                id: 'north-label', 
                position: Cesium.Cartesian3.fromDegrees(location[0], location[1] + 2, location[2]),
                label : {
                    text : 'N',
                    font : '14pt monospace',
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    outlineWidth : 2,
                    verticalOrigin : Cesium.VerticalOrigin.TOP,
                    pixelOffset : new Cesium.Cartesian2(0, -14)
                }
            });
            viewer.entities.add({
                id: 'south-arrow',
                name: 'south',
                polyline : {
                    positions : Cesium.Cartesian3.fromDegreesArrayHeights([...location, location[0], location[1] - 2, location[2]]),
                    width : 6,
                    material : new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW)
                }
            });
            viewer.entities.add({
                id: 'south-label',
                position: Cesium.Cartesian3.fromDegrees(location[0], location[1] - 2, location[2]),
                label : {
                    text : 'S',
                    font : '14pt monospace',
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    outlineWidth : 2,
                    verticalOrigin : Cesium.VerticalOrigin.TOP,
                    pixelOffset : new Cesium.Cartesian2(0, -14)
                }
            });
            viewer.entities.add({
                id: 'east-arrow',
                name: 'east',
                polyline : {
                    positions : Cesium.Cartesian3.fromDegreesArrayHeights([...location, location[0] + 3, location[1], location[2]]),
                    width : 6,
                    material : new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW)
                }
            });
            viewer.entities.add({
                id: 'east-label',
                position: Cesium.Cartesian3.fromDegrees(location[0] + 3, location[1], location[2]),
                label : {
                    text : 'E',
                    font : '14pt monospace',
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    outlineWidth : 2,
                    verticalOrigin : Cesium.VerticalOrigin.TOP,
                    pixelOffset : new Cesium.Cartesian2(0, -14)
                }
            });
            viewer.entities.add({
                id: 'west-arrow',
                name: 'west',
                polyline : {
                    positions : Cesium.Cartesian3.fromDegreesArrayHeights([...location, location[0] - 3, location[1], location[2]]),
                    width : 6,
                    material : new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW)
                }
            });
            viewer.entities.add({
                id: 'west-label',
                position: Cesium.Cartesian3.fromDegrees(location[0] - 3, location[1], location[2]),
                label : {
                    text : 'W',
                    font : '14pt monospace',
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    outlineWidth : 2,
                    verticalOrigin : Cesium.VerticalOrigin.TOP,
                    pixelOffset : new Cesium.Cartesian2(0, -14)
                }
            });
            Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(location[0] - 90, Math.max(location[1]-90, -90), location[0] + 90, Math.min(location[1]+90, 90));
            //flyToEntity(viewer, user, true);
            viewer.camera.flyHome();
        });

        LocationService.registerHome(() => {
            viewer.trackedEntity = null;
            viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(...this.home),
                complete: () => {
                    const selectedSatellite = SatelliteService.getSelected();
                    if (selectedSatellite) {
                        viewer.camera.lookAt(selectedSatellite.model.entity.position.getValue(viewer.clock.currentTime), viewer.scene.camera.position);
                        LocationService.setBearing(viewer.scene.camera.heading, viewer.scene.camera.pitch);
                    } else {
                        viewer.camera.lookUp(Cesium.Math.toRadians(95));
                    }
                    viewer.camera.setView({orientation: {roll:0, pitch: viewer.camera.pitch, heading: viewer.camera.heading}});

                    const scene = viewer.scene;
                    const canvas = viewer.canvas;
                
                    scene.screenSpaceCameraController.enableRotate = false;
                    scene.screenSpaceCameraController.enableTranslate = false;
                    scene.screenSpaceCameraController.enableZoom = false;
                    scene.screenSpaceCameraController.enableTilt = false;
                    scene.screenSpaceCameraController.enableLook = false;
                
                    let startMousePosition, mousePosition, isLooking;
                    const handler = new Cesium.ScreenSpaceEventHandler(canvas);
                
                    handler.setInputAction(function(movement) {
                        isLooking = true;
                        mousePosition = startMousePosition = Cesium.Cartesian3.clone(movement.position);
                    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
                
                    handler.setInputAction(function(movement) {
                        startMousePosition = movement.startPosition;
                        mousePosition = movement.endPosition;
                    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
                
                    handler.setInputAction(function(position) {
                        isLooking = false;
                    }, Cesium.ScreenSpaceEventType.LEFT_UP);
                    const onTickFunc = function(clock) {
                        if (LocationService.getMode() === LocationService.MODES.GLOBE_MODE) {
                            viewer.clock.onTick.removeEventListener(onTickFunc);
                            handler.destroy();
                            return;
                        }
                        const camera = viewer.camera;
                    
                        if (isLooking && !LocationService.getLiveBearingsMode()) {
                            const width = canvas.clientWidth;
                            const height = canvas.clientHeight;
                            const x = -(mousePosition.x - startMousePosition.x) / width;
                            const y = -(mousePosition.y - startMousePosition.y) / height;
                            const lookFactor = 2;
                            const heading = camera.heading + (x * lookFactor), pitch = camera.pitch - (y * lookFactor);
                            camera.setView({orientation:{roll: 0, pitch, heading}});

                            LocationService.setBearing(heading, pitch);
                        }
                        if (LocationService.getLiveBearingsMode()) {
                            let heading = LocationService.getBearings(), pitch = LocationService.getPitch();
                            camera.setView({orientation:{roll:0, pitch, heading}})
                        }
                    };    
                    viewer.clock.onTick.addEventListener(onTickFunc);
                }
            });
            
        })

        LocationService.registerDetach(() => {
            viewer.scene.screenSpaceCameraController.enableRotate = true;
            viewer.scene.screenSpaceCameraController.enableTranslate = true;
            viewer.scene.screenSpaceCameraController.enableZoom = true;
            viewer.scene.screenSpaceCameraController.enableTilt = true;
            viewer.scene.screenSpaceCameraController.enableLook = true;
            const selectedSatellite = SatelliteService.getSelected();
            if (selectedSatellite) {
                flyToEntity(viewer, selectedSatellite.model.entity)
            } else {
                //viewer.scene.camera.flyHome();
                //flyToEntity(viewer, user, true);
                viewer.trackedEntity = null;
                viewer.camera.flyHome();
            }
        });

        LocationService.registerPick(val => {
            if (!val) {return;}
            if (!viewer.scene.pickPositionSupported) {return;}
            const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                var cartesian = viewer.camera.pickEllipsoid(
                    movement.position,
                    viewer.scene.globe.ellipsoid
                );
                if (cartesian) {
                    var cartographic = Cesium.Cartographic.fromCartesian(cartesian);
                    var longitude = Cesium.Math.toDegrees(
                        cartographic.longitude
                    )
                    var latitude = Cesium.Math.toDegrees(
                        cartographic.latitude
                    );
                    LocationService.publish({coords: {latitude, longitude}, heading: null});
                    LocationService.setPicking(false);
                    handler.destroy();
                }
            }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
        });

        SatelliteService.onSelect((s, key) => {
            if (key === TRACKINGKEY) {return;}
            const entity = s && s.model ? s.model.entity : null;
            if (viewer.selectedEntity !== entity) {
                viewer.selectedEntity = entity;
            } 
        });

        Cesium.Transforms.preloadIcrfFixed(
            new Cesium.TimeInterval({
                start: new Cesium.JulianDate(J2000Epoch - JulCent),
                stop: new Cesium.JulianDate(J2000Epoch + JulCent)
            })
        ).then(() => {
            PlanetService.subscribe(planets => {
                this.planets.forEach(p => viewer.entities.remove(p.model.entity));
                planets.forEach(planet => {
                    planet.model = {};
                    planet.model.entity = createSatelliteEntity(planet, viewer);
                });
                this.planets = planets;
                PlanetService.subscribeStatus(planetsAreOn => {
                    planets.forEach(p => p.model.entity.show = planetsAreOn);
                }, TRACKINGKEY);
            });
        });

        SatelliteService.getSubscribe(satellites => {
            this.eventListenerStore.forEach(ev => {
                viewer.selectedEntityChanged.removeEventListener(ev);
                viewer.clock.onTick.removeEventListener(ev);
            });
            this.eventListenerStore.length = 0;
            const user = viewer.entities.getById('you');
            const arrows = arrowIds.map(aId => viewer.entities.getById(aId));
            const planets = viewer.entities.values.filter(e => e.id.includes('planet'));
            viewer.entities.removeAll();
            viewer.entities.add(user);
            planets.forEach(p => viewer.entities.add(p));
            arrows.forEach(arrow => viewer.entities.add(arrow));
            const selectedEntityChanged = entity => {
                const satellite = entity ?
                    satellites.find(s => s.id === entity.id || Object.keys(s.model).find(key => s.model[key] === entity)) :
                    null;
                if(satellite && entity !== satellite.model.entity) {
                    viewer.selectedEntity = satellite.model.entity;
                    return;
                }
                if (LocationService.getMode() === LocationService.MODES.GLOBE_MODE) {
                    if (satellite) {
                        flyToEntity(viewer, entity);
                    } else {
                        viewer.trackedEntity = null;
                        viewer.camera.flyHome();
                        //flyToEntity(viewer, user, true);
                        SatelliteService.select(null, TRACKINGKEY);
                    }
                }
                if (LocationService.getMode() === LocationService.MODES.POV_MODE) {
                    if (satellite) {
                        viewer.scene.camera.lookAt(satellite.model.entity.position.getValue(viewer.clock.currentTime), viewer.scene.camera.position );
                        LocationService.setBearing(viewer.scene.camera.heading, viewer.scene.camera.pitch);
                    } else {
                        viewer.trackedEntity = null;
                        SatelliteService.select(null, TRACKINGKEY);
                    }
                }
                if (satellite && satellite !== SatelliteService.getSelected()) {
                    SatelliteService.select(satellite, TRACKINGKEY);
                    SatelliteService.broadcast(satellites);
                }
                if (satellite) {
                    satellite.status.nextVisible = getNextVisible(viewer, satellite, user);
                }
            };
            this.eventListenerStore.push(selectedEntityChanged);
            viewer.selectedEntityChanged.addEventListener(selectedEntityChanged);
            viewer.entities.suspendEvents();
            satellites.forEach(s => {
                s.model = {};
                s.model.entity = createSatelliteEntity(s, viewer);
                if (this.selectedSatellite === s.id) {
                    viewer.selectedEntity = s.model.entity;
                }
            });
            viewer.entities.resumeEvents();

            const clockTickEventListener = clock => {
                if (++this.throttler.val < this.throttler.max) {
                    return;
                }
                this.throttler.val = 0;
                let changedVisibility = false;
                satellites.forEach(s => {
                    if (!s.model) {return;}
                    const wasVisible = s.status.visible;
                    const entityPosition = s.model.entity.position.getValue(clock.currentTime);
                    if (SatelliteService.getSelected() === s) {
                        const posVal = Cesium.Cartographic.fromCartesian(entityPosition);
                        s.setPosition([Cesium.Math.toDegrees(posVal.longitude), Cesium.Math.toDegrees(posVal.latitude)])
                            .setAltitude(posVal.height);
                    }
                    s.model.entity.show = s.status.on;
                    s.status.visible = !checkGlobeIntersection(viewer, user.position.getValue(), entityPosition);
                    if (s.status.visible !== wasVisible) {
                        if (s.status.visible) {
                            s.model.anchor.polyline.material = Cesium.Color.GREEN;
                            s.model.anchor.polyline.width = 2;
                        } else {
                            s.model.anchor.polyline.material = Cesium.Color.RED;
                            s.model.anchor.polyline.width = 1;
                        }
                        changedVisibility = true;
                    }
                    if (s.model.entity.path) {
                        if (s === SatelliteService.getSelected()) {
                            s.model.entity.path.material = selectedPathMaterial;
                            s.model.entity.path.show = true;
                        } else {
                            s.model.entity.path.material = unSelectedPathMaterial;
                        }
                    }
                });
                if (changedVisibility) {
                    SatelliteService.broadcast(satellites);
                }
            };
            this.eventListenerStore.push(clockTickEventListener);
            viewer.clock.onTick.addEventListener(clockTickEventListener);
        })

    }

    throttler = {val: 0, max: FRAME_RATE}

    eventListenerStore = []

    planetEventStore = []

    planets = []

    persistentEntities = {}

    render() {
        return (
            <div id="cesiumGlobe" className="globe" />
        );
    }
}

export default Globe;