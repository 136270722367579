import React, { Component } from 'react';
import SatelliteService from '../services/satellite-service';

class SatelliteStyler extends Component {

    state = {
        satellites: []
    }

    componentDidMount() {
        SatelliteService.get().then(
            satellites => this.setState({satellites})
        );
    }

    render() {
        const {rootStyle} = this.props;
        const {satellites} = this.state;
        return (<style>
            {satellites.filter(s => !s.id.toLowerCase().includes('starlink')).map(s => `${rootStyle}.${s.id} {background-image:url(/images/saticons/${SatelliteService.getDescription(s.id).icon});} `)}
        </style>);
    }
}

export default SatelliteStyler;