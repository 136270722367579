import React, { Component } from 'react';
import './AppFunctions.css';
import BearingIndicator from './BearingIndicator';
import SatelliteService from '../services/satellite-service';
import LocationService from '../services/location-service';
import SatelliteInfo from './SatelliteInfo';

class AppFunctions extends Component {
    state = {
        satellites: [],
        mode: LocationService.getMode(),
        selectedSatellite: SatelliteService.getSelected(),
        bearingsMode: LocationService.getLiveBearingsMode(),
        isPicking: LocationService.getPicking(),
    }

    componentDidMount() {
        SatelliteService.getSubscribe(satellites => this.setState({satellites}));
        SatelliteService.onSelect((selectedSatellite) => this.setState({selectedSatellite}));
        LocationService.registerPick(isPicking => this.setState({isPicking}));
    }

    seeOnlyVisibile = () => {
        const {satellites} = this.state;
        satellites.forEach(s => s.setVisibility(s.status.visible));
        SatelliteService.broadcast();
    }
    seeAll = () => {
        const {satellites} = this.state;
        satellites.forEach(s => s.setVisibility(true));
        SatelliteService.broadcast();
    }
    goHome = () => {
        if (LocationService.getMode() === LocationService.MODES.POV_MODE) {return;}
        LocationService.goHome();
        this.setState({mode: LocationService.getMode()});
    }

    seeWorld = () => {
        LocationService.detach();
        this.setState({mode: LocationService.getMode()});
    }

    toggleLiveBearingsMode = () => {
        if (!LocationService.bearingsAreAvailable()) {return;}
        const isLive = LocationService.getLiveBearingsMode();
        if (isLive) {
            LocationService.unwatchLiveBearings();
        } else {
            LocationService.watchLiveBearings();
        }
        this.setState({bearingsMode: LocationService.getLiveBearingsMode()});
    }

    setPickMode = () => {
        LocationService.getPicking() ? LocationService.setPicking(false) : LocationService.setPicking(true);
    }

    render() {
        const {mode, selectedSatellite, bearingsMode, isPicking} = this.state;
        return (
            <div className="app-functions">
                <SatelliteInfo satellite={selectedSatellite} />
                <div className="satellite-functions">
                    {mode === LocationService.MODES.POV_MODE && <BearingIndicator />}
                    <label>Change POV</label>
                    {mode === LocationService.MODES.POV_MODE && <input type="image" src="images/saticons/sputnik.png" title="Switch to space view" alt="Switch to space view" className="satellite-function-button space-view" onClick={this.seeWorld} />}
                    {mode === LocationService.MODES.GLOBE_MODE && <input type="image" src="images/saticons/satellite.png" title="Switch to Earth view" alt="Switch to space view" className="satellite-function-button earth-view" onClick={this.goHome} />}
                    <label>{mode === LocationService.MODES.GLOBE_MODE ? (isPicking ? 'Picking location...':'Pick location') : 'Live Mode'}</label>
                    {mode === LocationService.MODES.GLOBE_MODE && <input type="image" src="images/location-pick-earth.png" title="Pick your location on the globe" alt="Pick your location on the globe" className={`satellite-function-button ${isPicking ? 'active' : ''}`} onClick={this.setPickMode} />}
                    {mode === LocationService.MODES.POV_MODE && <input type="image" src="images/live-mode-earth.png" title="Activate live orientation" alt="Activate live orientation" className={`satellite-function-button ${bearingsMode ? 'active' : '' } ${LocationService.bearingsAreAvailable() ? 'abled' : 'disabled'}`} onClick={this.toggleLiveBearingsMode} />}
                </div>
            </div>
        );
    }
}

export default AppFunctions;