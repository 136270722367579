import Planet from '../models/Planet';
import * as Astronomy from 'astronomy-engine';

const planetMeta = [
  {
    name: "Sun",
    description: "",
    photos: [],
    icon: "sun.png"
  },
  {
    name: "Moon",
    description: "",
    photos: [],
    icon: "moon.png"
  },
  {
    name: "Mercury",
    description: "",
    photos: [],
    icon: "mercury.png"
  },
  {
    name: "Venus",
    description: "",
    photos: [],
    icon: "venus.png"
  },
  {
    name: "Mars",
    description: "",
    photos: [],
    icon: "mars.png"
  },
  {
    name: "Jupiter",
    description: "",
    photos: [],
    icon: "jupiter.png"
  },
  {
    name: "Saturn",
    description: "",
    photos: [],
    icon: "saturn.png"
  },
  {
    name: "Neptune",
    description: "",
    photos: [],
    icon: "neptune.png"
  },
  {
    name: "Uranus",
    description: "",
    photos: [],
    icon: "uranus.png"
  }
];

const PlanetService = () => {
  const planets = [];
  const waiters = [];
  let gotPlanets = false;
  let showingPlanets = true;

  const getPlanetsEngine = () => {
    gotPlanets = false;
    const tIncrement = 1000 * 600; // every 10 mins
    const now = new Date();
    const fromDate = new Date(now.valueOf() - (10 * 60 * 1000)); // 10 mins ago
    const toDate = new Date(now.valueOf() + (2 * 60 * 60 * 1000)); // next 2 hours
    const timeMap = [];

    let dateTracker = fromDate;
    while (dateTracker <= toDate) {
        timeMap.push(dateTracker);
        dateTracker = new Date(dateTracker.valueOf() + tIncrement);
    }
    ['Sun', 'Mercury', 'Venus', 'Moon', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'].forEach(body => {
      let planet = new Planet(body);
      planets.push(planet);
      timeMap.forEach(t => {
        const tA = new Astronomy.AstroTime(t);
        /*const vec = Astronomy.RotateVector(Astronomy.Rotation_HOR_ECL(t, observer), Astronomy.GeoVector(body, t, true));
        const sp = Astronomy.SphereFromVector(vec);*/
        let vec = Astronomy.GeoVector(body, tA, true);
        //vec = Astronomy.RotateVector(Astronomy.Rotation_ECL_HOR(t, observer), vec)
        const sp = Astronomy.SphereFromVector(vec);
        planet.addToPath(vec, sp.dist, t);
      });
    });
    gotPlanets = true;
    setTimeout(getPlanetsEngine, 60*60*1000);
  }

  getPlanetsEngine();

  const statusWatchers = {};

  return {
    subscribe: func => {
      waiters.push(func);
      if (gotPlanets) {
        func(planets);
      }
    },
    getDetails: (name) => planetMeta.find(p => p.name === name),
    planets,
    planetsAreShowing: () => showingPlanets,
    togglePlanets: () => {
      showingPlanets = !showingPlanets;
      Object.keys(statusWatchers).forEach(key => {
        statusWatchers[key](showingPlanets);
      })
    },
    subscribeStatus: (func, key) => statusWatchers[key] = func,
  }
}

export default PlanetService();

