import React, { Component } from 'react';
import { Capacitor, Plugins } from '@capacitor/core';
import { AdSize, AdPosition } from '@capacitor-community/admob';
const { AdMob } = Plugins;

const options = {
  adId: 'ca-app-pub-2197773586929118/6673165462',
  adSize: AdSize.BANNER,
  position: AdPosition.BOTTOM_CENTER,
  margin: 0
};

export default class Banner extends Component {

  componentDidMount() {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    AdMob.showBanner(options).catch(err => console.log(err));
    AdMob.addListener('onAdLoaded', (info) => {
      //console.log(info);
    });
  }

  componentWillUnmount() {
    AdMob.removeBanner().catch(err => console.log(err));
  }
  render() {
    return (<div className="showing-banner-ad" />)
  }
}
